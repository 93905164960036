.container {
  width: 900px;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 10px;

  @media screen and (max-width: 1330px) {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(223, 229, 234);

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }

    subtitle {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }

    button {
      // color: rgb(55, 153, 235);
      // background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
    }
  }

  .subHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    width: fit-content;

    svg {
      margin-right: 10px;
    }
  }

  .field {
    margin: 10px 0;

    // padding: 5px 20px;
    p {
      font-weight: 500;
      margin-bottom: 10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="number"],
    input[type="month"],
    .grayContainer,
    textarea {
      width: 100%;
      border: #f8f8f8;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 10px 5px;
      outline: none;
    }
  }
}

.styles.helperInfo {
  position: relative;
  cursor: pointer;
}

.styles.helperInfo::after {
  content: attr(title);
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f8f8;
  color: black;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
  display: none;
  white-space: nowrap;
}

.styles.helperInfo:hover::after {
  display: block;
}

.dropdownStyle {
  width: 100%;
  border: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 11.5px 5px;
  outline: none;
  resize: vertical;
}

.yearDropdown {
  @extend .dropdownStyle;
  background: #f8f8f8 url("../../../public/calendar.png") no-repeat 7px 12.5px;
  padding-left: 32px;
  background-size: 20px;
}

.countryDropdown {
  @extend .dropdownStyle;
  background: #f8f8f8 url("../../../public/country.png") no-repeat 7px 12.5px;
  padding-left: 32px;
  background-size: 20px;
}

.custDropdownStyle,
.roleBtn {
  width: 150px;
  color: gray;
  border: 1px dashed gray;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-right: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0px 10px;
  outline: none;
  resize: vertical;
}

.react-datetime-picker,
.react-datetime-picker--closed,
.react-datetime-picker--enabled {
  border: 1px solid red;
  background-color: red;
}

.react-datetime-picker__wrapper {
  border-color: red !important;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    width: 120px;
  }
}

.secondary {
  background-color: #fff;
  color: grey;
  margin-right: 10px;
  border: grey;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.createProfLink {
  color: #000;
}

.fullWidth {
  width: 100%;
}

.btns {
  margin-top: 10px;
  display: flex;
  justify-content: right;

  button {
    border: 0;
    outline: none;
    margin-left: 10px;
    padding: 7px 10px;
    border-radius: 10px;
    width: 150px;
    font-weight: 700;

    & svg {
      color: white;
      font-size: 30px;
    }

    &.small {
      width: fit-content;
      padding: 5px 5px;
    }

    &:active {
      transform: translateY(2px);
    }

    &.gray {
      background-color: #fff;
      border: 1px solid #dddddd;
      color: #898989;
    }

    &.green {
      background-color: #1bbb4e;
      color: #fff;
    }

    &.red {
      background-color: #f70000;
      color: #fff;
    }
  }
}

.switch {
  input[type="checkbox"] {
    &:checked {
      background-color: #000;
      border: 1px #000;
    }
    box-shadow: none;
  }
}


.modal {
  display: flex; /* Change display property to flex */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;

}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}