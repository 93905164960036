.icon {
    font-size: 24px;
}

.fileContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fileIcon {
        object-fit: contain;
        object-position: center;
    }
    .openFileIcon {
        font-size:20px;
        cursor: pointer;
    }
    .cancelContainer {
        position: absolute;
        right: 15px;

        svg {
            font-size: 22px;
            color: #d34c43;
            cursor: pointer;
            margin-left: 10px;
            transition: all 0.1s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.halfOpacity {
    opacity: 0.5;
}