.container {
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(221, 221, 221);
    border-radius: 5px;
  }

  
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    padding: 10px 0;
    column-gap: 10px;
    & > div {
      width: 100%;
      height: 100%;
      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        div {
          background-color: #fff;
          border-radius: 15px;
          padding: 10px 20px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          p {
            font-weight: 700;
            font-size: 20px;
          }
          span {
            font-weight: 900;
            font-size: 25px;
          }
        }
      }
    }
  }
  // .feed {
  //   background-color: #fff;
  //   border-radius: 15px;
  //   padding: 15px;
  //   & > p {
  //     font-weight: 700;
  //     font-size: 25px;
  //   }
  //   table {
  //     width: 100%;
  //     background-color: rgb(251, 251, 251);
  //     thead {
  //       background-color: #000;
  //       color: #fff;
  //       tr {
  //         th {
  //           padding: 8px 0 8px 5%;
  //           &:first-child {
  //             border-radius: 5px 0 0 5px;
  //           }
  //           &:last-child {
  //             border-radius: 0 5px 5px 0;
  //           }
  //         }
  //       }
  //     }
  //     tbody {
  //       tr {
  //         td {
  //           padding-left: 5%;
  //           padding: 5px 0 5px 5%;
  //           font-size: 20px;
  //         }
  //       }
  //     }
  //   }
  // }

  .recentActivity {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    .tableContainer {
      max-height: 50rem;
      overflow-y: scroll;
      margin-top: 20px;
      padding: 0;

      table {
        margin-top: 0;
        width: 100%;
        background-color: rgb(251, 251, 251);

        thead {
          background-color: #000;
          font-size: 16px;
          color: #fff;
          position: sticky;
          top: 0;

          tr {
            th {
              // padding: 8px 0 8px 5%;
              padding: 12px 8px;

              &:first-child {
                border-radius: 5px 0 0 5px;
              }

              &:last-child {
                border-radius: 0 5px 5px 0;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              // padding-left: 5%;
              // padding: 5px 0 5px 5%;
              padding: 12px 8px;
              font-size: 14px;

              svg {
                cursor: pointer;
                font-size: 20px;
                margin-left: 10px;

                transition: all 0.1s;

                &:hover {
                  transform: scale(1.1);
                }

                &:nth-child(1) {
                  color: #afafaf;
                }

                &:nth-child(2) {
                  color: #5cb559;
                }

                &:nth-child(3) {
                  color: #d34c43;
                }
              }
            }
          }
        }
      }
    }
  }

  .watchlist {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    .tableRow {
      background-color: #fff;

      td {
        padding: 0px;
      }
    }
    .tablePersonInfo {
      h4 {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }
    }
    .tableData {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: none;
      padding: 0.5rem 0.5rem;
      border-right: 1px solid #ddd;
      margin-top: 5px;
      border-bottom: none;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin: 0;
        margin-top: 5px;
      }
    }
    .tableDataDark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: none;
      background-color: #000;
      padding: 0.5rem;
      margin-top: 5px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  .feed {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    position: relative;

    .containerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
  
      .dropdownMenu {
        z-index: 1050; // Ensures visibility over other components
      }
    }

    .tableContainer {
      max-height: 22rem;
      overflow-y: scroll;
      margin-top: 20px;
      padding: 0;
    }

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }

    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    table {
      margin-top: 0;
      width: 100%;
      background-color: rgb(251, 251, 251);

      thead {
        background-color: #000;
        font-size: 16px;
        color: #fff;
        position: sticky;
        top: 0;

        tr {
          th {
            // padding: 8px 0 8px 5%;
            padding: 12px 8px;

            &:first-child {
              border-radius: 5px 0 0 5px;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            // padding-left: 5%;
            // padding: 5px 0 5px 5%;
            padding: 12px 8px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.centeredButton {
  display: flex;
  justify-content: center;
  margin-top: 20px; // Adjust the margin as needed

  button {
    background-color: black;
    color: white; // Set text color to white for better visibility
    padding: 10px 20px; // Adjust padding as needed
    border: none;
    cursor: pointer;
  }
}



/* Dashboard.module.scss */

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #b9b9b9;
  }
  50% {
    background-color: #d1d1d1;
  }
  100% {
    background-color: #b4b4b4;
  }
}

.skeleton-row {
  height: 100%; /* Adjust height as needed */
  display: block;
  width: 100%;
  margin-bottom: 10px; /* Space between skeleton rows */
}

.skeleton-cell {
  width: 100%;
  height: 100%;
}


.topBar {
  display: flex;
  justify-content: space-between; /* Align content to the edges */
  align-items: center; /* Vertically center the items */
  //padding: 16px 24px; /* Add some padding around */
  background-color: #ffffff; /* Optional: Set a background color */
  
}

.topBar p {
  font-size: 25px; /* Adjust font size */
  font-weight: 700; /* Make the font bold */
  color: #212529; /* Set text color */
  margin: 0; /* Remove any default margin */
}

.threeDotMenu {
  cursor: pointer; /* Show pointer cursor on hover */
  font-size: 25px; /* Adjust icon size */
  color: #212529; /* Optional: Icon color */
}

.threeDotMenu:hover {
  color: #5e5e5e; /* Optional: Change icon color on hover */
}

.icon {
  vertical-align: middle; /* Align the icon vertically with the text */
}
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  
}

.spinnerMask {
  background-color: rgba(0, 0, 0, 0.1); // Optional dimming effect
  height: 100%;
  width: 100%;
  position: absolute;
}

.customSpinner .ant-spin-dot-item {
  background-color: black !important;
}

.addedRow {
  background-color: #f3f3f3;
}
.editedRow {
  background-color: #e3e3e3;
}


.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Transparent background overlay
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it's above all other elements
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modalContent h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.modalContent p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 20px;
}

.modalContent button {
  background-color: #1b1b1b; // Primary button color
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modalContent button:hover {
  background-color: #000000; // Darker shade for hover
}

.tableContainer {
  width: 100%;
  overflow-x: auto; /* Adds scroll for smaller screens */
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border: 1px solid rgb(249, 249, 249);
  border-radius: 7px;
  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.089);

  th,
  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle; /* Ensures vertical alignment */
    height: 60px;
    font-weight: 500;
    line-height: 1.5;
  }

  th {
    background-color: #000000;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(255, 255, 255);
  }

  tr {
    transition: background-color 0.2s ease;
    border-bottom: 1px solid rgb(217, 217, 217);

    &:hover {
      background-color: #f9f9f9;
    }

    &.completedRow {
      background-color: #f4f4f4;
      color: #999;
    }
  }

  .readMore {
    color: rgb(32, 32, 32);
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;

    &:hover {
      text-decoration: none;
    }
  }
}

.iconCell {
  text-align: center;

  .viewIcon {
    font-size: 18px;
    cursor: pointer;
    color: #5f5f5f;
    transition: transform 0.2s, color 0.2s;

    &:hover {
      transform: scale(1.2);
      color: #272727;
    }
  }
}


.requestLink {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #0056b3;
  }

  &.disabled {
    color: grey;
    //cursor: not-allowed;
    text-decoration: none;
  }
}

