.team {
    width: 100px;
}


.singleInputs td {
    width: 34px !important;
    text-align: center;
}

.singleInputs input {
    width: 30px !important;
}

.singleInputs span {
    font-weight: bold;
}


// Updated CSS (statsTable.module.scss)
.doubleInputs {
    min-width: 120px; // Prevents cell from collapsing
  }
  
  .doubleInputsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  
  .doubleInputs input {
    width: 40px !important;
    text-align: center;
    box-sizing: border-box;
    padding: 0 2px; 
  }
  
  .table {
    margin: 0;
    min-width: 800px;
  }
 