/* Container Styles */
.container {
  background-color: #fff;
  border-radius: 7px;
  margin: 30px auto 0;
  padding: 20px 20px 10px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: auto; 
  -webkit-overflow-scrolling: touch;
}

/* Title Section */
.title {
  border-bottom: 1px solid rgb(223, 229, 234);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  h4 {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
}

/* Form Elements */
.field {
  margin: 10px 0;

  p {
    font-weight: 500;
    margin-bottom: 10px;
  }

  input[type="text"],
  select,
  textarea {
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 5px;
    padding: 10px 5px;
    resize: vertical;
    width: 100%;
  }
}

.table-container {
  overflow-x: auto;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  -webkit-overflow-scrolling: touch;
}

/* Table Styles */
.table {
  background-color: rgb(251, 251, 251);
  table-layout: auto;
  min-width: 1440px; /* Reduced from 1770px */
  width: 100%;
  margin: 0;
}

/* Table header and cells */
.table th,
.table td {
  &.date { width: 100px; }
  &.type { width: 100px; }
  &.team { width: 100px; }
  &.opponent { width: 100px; }
  &.result { width: 140px; }
  &.min { width: 50px; }
  
  /* Statistical columns */
  &.fgPct, &.threeFgPct, &.ftPct, &.orb, &.drb, &.trb,
  &.ast, &.blk, &.stl, &.to, &.pf, &.pts {
    width: 60px; /* Reduced from 85px */
  }

  &.fgFga, &.threePThreePA, &.ftFta {
    width: 100px;
  }


  /* Action column */
  &:last-child {
    width: 100px;
  }
}

/* Table header */
.table thead {
  background-color: #000;
  color: #fff;
  font-size: 16px;

  th {
    font-size: 14px; /* Reduced from 14px */
    padding: 8px 4px; /* Reduced padding */
    white-space: nowrap;
    text-align: center;

    &:first-child {
      border-radius: 5px 0 0 5px;
      padding-left: 15px;
      text-align: left;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      background: #000;
      z-index: 2;
    }
  }
}

/* Table body */
.table tbody {
  td {
    font-size: 14px;
    padding: 12px 8px;
    vertical-align: middle;

    &.date {
      padding-left: 15px;
      text-align: left;
    }

    &:not(.date) {
      text-align: center;
    }
  }
}

/* Editable Inputs */
.editableInput {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 8px;
  text-align: center;
  width: 100%;
  max-width: 100%;

  &:focus {
    border-color: transparent;
    outline: 2px solid #2196F3;
  }

  select {
    padding: 4px 8px;
    width: 100%;
    background: white;
  }
}

/* Result Input Group */
.resultInput {
  align-items: center;
  display: flex;
  gap: 4px;
  
  select {
    width: 50px;
    padding: 4px;
  }
  
  input {
    flex-grow: 1;
    padding: 4px 8px;
  }
}

.splitInput {
  display: flex;
  align-items: center;
  gap: 4px;
  
  input {
    width: 40px;
    text-align: center;
    padding: 4px;
  }
  
  span {
    color: #666;
  }
}

/* Statistical Columns */
td {
  &.fgFga, &.fgPct, &.threePThreePA, &.threeFgPct,
  &.ftFta, &.ftPct, &.orb, &.drb, &.trb,
  &.ast, &.blk, &.stl, &.to, &.pf, &.pts {
    .editableInput {
      padding: 4px;
      text-align: center;
      max-width: 50px;
    }
  }
}

/* Action Elements */
.actionIcon {
  display: flex;
  gap: 6px;
  justify-content: center;

  svg {
    cursor: pointer;
    font-size: 20px;
    transition: all 0.1s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

/* Autocomplete Styles */
.autocomplete {
  position: relative;

  .suggestions {
    background: white;
    border: 1px solid #ddd;
    left: 0;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1000;

    .suggestionItem {
      cursor: pointer;
      padding: 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
}

/* Buttons & Actions */
.buttonAdd {
  background: initial;
  border: 1px dashed #7a7a7a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  padding: 8px 15px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    width: 120px;

    &.secondaryButton {
      background-color: #fff;
      border: 1px solid grey;
      color: grey;
      margin-right: 20px;
    }
  }
}

/* Card List Styles */
.cardList {
  padding: 20px;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }

  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;

    button {
      border-radius: 10px;
      padding: 8px 62px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .table-container {
    overflow-x: scroll;
  }
}

