body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "termina";
  src: url("./fonts/Termina\ W05\ Bold.ttf");
}
.react-datetime-picker__wrapper {
  /* border-color: rgba(55, 154, 235, 0.5) !important; */
  border-radius: 5px;
  width: 100%;
  border: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 10px 5px;
  outline: none;
  resize: vertical;
  border: none !important;
}
.react-datetime-picker {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accordion-button {
  background-color: black !important;
  color: #ffff !important;
  border: none !important;
  box-shadow: none !important;
}
.accordion-button::after {
  background-image: url("./Assets/icons/arrow-down-sign-to-navigate.png") !important;
}

.modal-content {
  background-color: #f0f0f0;
}

.modal {
  z-index: 995 !important;
}

.modal-backdrop {
  z-index: 994;
}
