.container {
  background-color: #000;
  height: max(500px, 100vh);
  .content {
    padding: 0 10px;
    height: 100%;
    width: min(100%, 700px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: #fff;
      width: 100%;
      font-size: 35px;
      margin-bottom: 20px;
      font-family: "termina", sans-serif;
    }
    .inputContainer {
      width: 100%;
      background-color: #fff;
      margin-bottom: 20px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 5px;
      svg {
        color: #a7a7a7;
        font-size: 25px;
      }
      input {
        width: 90%;
        border: 0;
        outline: none;
        &::placeholder {
          opacity: 0.3;
        }
      }
    }
    p {
      color: #c0c0c0;
      font-size: 1.1em;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 60%;
      button {
        width: 100%;
        margin-bottom: 10px;
        height: 50px;
        border: 0;
        outline: none;
        border-radius: 5px;
        font-weight: 700;
        font-size: 1.2em;
        &:nth-child(1) {
          background-color: #1bbb4e;
          color: #fff;
        }
        &:nth-child(2) {
          background-color: #fff;
          color: #909090;
        }
      }
    }
  }
  .modalContainer {
    background-color: red;
    height: 100px;
    width: 100px;
  }
}
