.container {
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: relative;
  .menuBtn {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: rgba(0, 0, 0, 0.103);
    border: 0;
    outline: none;
    border-radius: 50%;
    font-size: 20px;
    width: 40px;
    height: 40px;
  }
  .content {
    width: 100%;
    height: 100vh;
    padding: 40px;
    overflow: auto;
    @media screen and (max-width: 500px) {
      padding: 50px 10px 20px 10px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      button {
        color: rgb(55, 153, 235);
        background-color: #fff;
        border: 0;
        outline: 0;
        margin: 0 5px;
        padding: 5px 10px;
        border-radius: 15px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
        &:last-child {
          background-color: rgb(55, 153, 235);
          color: #fff;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    button {
      width: 120px;
    }
  }
  .secondary {
    background-color: #fff;
    color: grey;
    margin-right: 10px;
    border: grey;
  }
}
