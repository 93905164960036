.container {
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(221, 221, 221);
    border-radius: 5px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    padding: 10px 0;
    column-gap: 10px;
    & > div {
      width: 100%;
      height: 100%;
      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        div {
          background-color: #fff;
          border-radius: 15px;
          padding: 10px 20px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          p {
            font-weight: 700;
            font-size: 20px;
          }
          span {
            font-weight: 900;
            font-size: 25px;
          }
        }
      }
    }
  }
  // .feed {
  //   background-color: #fff;
  //   border-radius: 15px;
  //   padding: 15px;
  //   & > p {
  //     font-weight: 700;
  //     font-size: 25px;
  //   }
  //   table {
  //     width: 100%;
  //     background-color: rgb(251, 251, 251);
  //     thead {
  //       background-color: #000;
  //       color: #fff;
  //       tr {
  //         th {
  //           padding: 8px 0 8px 5%;
  //           &:first-child {
  //             border-radius: 5px 0 0 5px;
  //           }
  //           &:last-child {
  //             border-radius: 0 5px 5px 0;
  //           }
  //         }
  //       }
  //     }
  //     tbody {
  //       tr {
  //         td {
  //           padding-left: 5%;
  //           padding: 5px 0 5px 5%;
  //           font-size: 20px;
  //         }
  //       }
  //     }
  //   }
  // }

  .recentActivity {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    .tableContainer {
      max-height: 50rem;
      overflow-y: scroll;
      margin-top: 20px;
      padding: 0;

      table {
        margin-top: 0;
        width: 100%;
        background-color: rgb(251, 251, 251);

        thead {
          background-color: #000;
          font-size: 16px;
          color: #fff;
          position: sticky;
          top: 0;

          tr {
            th {
              // padding: 8px 0 8px 5%;
              padding: 12px 8px;

              &:first-child {
                border-radius: 5px 0 0 5px;
              }

              &:last-child {
                border-radius: 0 5px 5px 0;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              // padding-left: 5%;
              // padding: 5px 0 5px 5%;
              padding: 12px 8px;
              font-size: 14px;

              svg {
                cursor: pointer;
                font-size: 20px;
                margin-left: 10px;

                transition: all 0.1s;

                &:hover {
                  transform: scale(1.1);
                }

                &:nth-child(1) {
                  color: #afafaf;
                }

                &:nth-child(2) {
                  color: #5cb559;
                }

                &:nth-child(3) {
                  color: #d34c43;
                }
              }
            }
          }
        }
      }
    }
  }

  .watchlist {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    .tableRow {
      background-color: #fff;

      td {
        padding: 0px;
      }
    }
    .tablePersonInfo {
      h4 {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }
    }
    .tableData {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: none;
      padding: 0.5rem 0.5rem;
      border-right: 1px solid #ddd;
      margin-top: 5px;
      border-bottom: none;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin: 0;
        margin-top: 5px;
      }
    }
    .tableDataDark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: none;
      background-color: #000;
      padding: 0.5rem;
      margin-top: 5px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  .feed {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    .tableContainer {
      max-height: 22rem;
      overflow-y: scroll;
      margin-top: 20px;
      padding: 0;
    }

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }

    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    table {
      margin-top: 0;
      width: 100%;
      background-color: rgb(251, 251, 251);

      thead {
        background-color: #000;
        font-size: 16px;
        color: #fff;
        position: sticky;
        top: 0;

        tr {
          th {
            // padding: 8px 0 8px 5%;
            padding: 12px 8px;

            &:first-child {
              border-radius: 5px 0 0 5px;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            // padding-left: 5%;
            // padding: 5px 0 5px 5%;
            padding: 12px 8px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.centeredButton {
  display: flex;
  justify-content: center;
  margin-top: 20px; // Adjust the margin as needed

  button {
    background-color: black;
    color: white; // Set text color to white for better visibility
    padding: 10px 20px; // Adjust padding as needed
    border: none;
    cursor: pointer;
  }
}

