.container {
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(221, 221, 221);
    border-radius: 5px;
  }

  .headerLeft {
    flex: 1; /* Take up remaining space */
  }

  .headerRight {
    flex-shrink: 0; /* Don't allow button to shrink */
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ///padding: 10px 0;

    .searchContainer {
      background: #fff;
      width: 50%;
      border-radius: 15px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      input {
        width: 95%;
        border: 0;
        outline: none;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }

    &>div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      column-gap: 10px;

      svg {
        cursor: pointer;
      }

      hr {
        margin-left: 10px;
        height: 20px;
        width: 1px;
        border: 1px solid #000;
      }

      .profile {
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: 40px;
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.486);
          border-radius: 50%;
          margin-right: 10px;
        }

        span {
          margin-right: 80px;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    padding: 10px 0;
    column-gap: 10px;

    &>div {
      width: 100%;
      height: 100%;

      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        div {
          background-color: #fff;
          border-radius: 15px;
          padding: 10px 20px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;

          p {
            font-weight: 700;
            font-size: 20px;
          }

          span {
            font-weight: 900;
            font-size: 25px;
          }
        }
      }

      &:last-child {
        background-color: #fff;
        border-radius: 15px;
        padding: 10px;

        &>p {
          font-weight: 700;
          font-size: 25px;
          margin-left: 10px;
        }

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .msgs {
          overflow: auto;
          width: 100%;
          margin-bottom: 10px;

          &>div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;

            &.currentUser {
              justify-content: flex-end;

              &>div {
                flex-direction: row-reverse;
              }
            }

            &>div {
              background-color: rgb(249, 249, 249);
              width: 60%;
              border-radius: 10px;
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              div {
                p {
                  &:nth-child(1) {
                    font-weight: 700;
                    margin-bottom: 0;
                  }

                  &:last-child {
                    margin-bottom: 0;
                    opacity: 0.5;
                    font-size: 11px;
                  }
                }
              }

              .avatar {
                width: 40px;
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.486);
                border-radius: 50%;
                margin: 0 10px;
              }
            }
          }
        }

        &>div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          column-gap: 10px;

          input {
            width: 100%;
            background-color: rgb(249, 249, 249);
            border: 0;
            outline: none;
            border-radius: 15px;
            padding: 10px;
          }

          button {
            border: 0;
            border-radius: 15px;
            background-color: #000;
            color: #fff;
            height: 100%;
            width: 70px;
          }
        }
      }
    }
  }

  .feed {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    .tableContainer {
      max-height: 17rem;
      overflow-y: scroll;
      margin-top: 20px;
      padding: 0;
    }


    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }

    &>p {
      font-weight: 700;
      font-size: 25px;
    }

    table {
      margin-top: 0;
      width: 100%;
      background-color: rgb(251, 251, 251);

      thead {
        background-color: #000;
        font-size: 16px;
        color: #fff;
        position: sticky;
        top:0;

        tr {
          th {
            // padding: 8px 0 8px 5%;
            padding: 12px 8px;

            &:first-child {
              border-radius: 5px 0 0 5px;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            // padding-left: 5%;
            // padding: 5px 0 5px 5%;
            padding: 12px 8px;
            font-size: 14px;

            svg {
              cursor: pointer;
              font-size: 20px;
              margin-left: 10px;

              transition: all 0.1s;

              &:hover {
                transform: scale(1.1);
              }

              &:nth-child(1) {
                color: #afafaf;
              }

              &:nth-child(2) {
                color: #5cb559;
              }

              &:nth-child(3) {
                color: #d34c43;
              }
            }
          }
        }
      }
    }
  }

  .filterCard {
    background-color: #000;
    border-radius: 8px;
    display: flex;
    margin-right: 10px;
    width: 10rem;
    height: 100%;

    &>div {
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 10px;

      &:first-child {
        border-right: 1px solid #ddd;
      }
    }
  }

  .title {
    margin-top: 20px;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }
  }

  .tablePersonInfo {
    h4 {
      font-size: 15px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }

    h6 {
      font-size: 12px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }

    p {
      font-size: 10px;
      font-weight: 400;
      color: #a8a8a8;
      margin: 0;
    }
  }

  .tableRow {
    background-color: #fff;

    td {
      padding: 0px;
    }
  }

  .tableData {
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      display: flex;
      width: 100%;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: none;
      padding: 0.5rem;
      border-left: 1px solid #ddd;

      border-bottom: none;

      p {
        font-size: 10px;
        font-weight: 400;
        color: #a8a8a8;
        margin: 0;
      }

      h4 {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin: 0;
        margin-top: 5px;
      }

      &:first-child {
        border-left: none;
      }
    }
  }

  .tableDataDark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: none;
    background-color: #000;
    padding: 0.5rem 0.5rem;

    border-radius: 15px;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #a8a8a8 !important;
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: #fff !important;
      margin: 0;
      margin-top: 5px;
    }
  }

  .actionIcon {
    display: flex;
    justify-content: space-evenly;
    font-size: medium;
  }

  .singleSection {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 20px;
      font-weight: 800;
      margin: 0;
    }

    svg {
      cursor: pointer;
      font-size: 20px;
      transition: all 0.1s;
      margin-left: 10px;

      &:hover {
        transform: scale(1.1);
      }

      &:nth-child(1) {
        color: #afafaf;
      }

      &:nth-child(2) {
        color: #5cb559;
      }

      &:nth-child(3) {
        color: #d34c43;
      }
    }
  }

  .uploadSection {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    margin-top: 20px;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }

    input {
      border: none;
      background-color: #f8f8f8;
      width: 100%;
      border-radius: 8px;
      margin-top: 5px;
    }
  }

  .profileInto {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    height: 100%;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    svg {
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;

      transition: all 0.1s;

      &:hover {
        transform: scale(1.1);
      }

      &:nth-child(1) {
        color: #afafaf;
      }

      &:nth-child(2) {
        color: #5cb559;
      }

      &:nth-child(3) {
        color: #d34c43;
      }
    }
  }

  .profileComplete {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    height: 100%;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }
  }
}