.container {
  .content {
    height: max(80vh, 500px);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    & > div:first-child {
      //width: 450px;
      width: 100%;
      background-color: #fff;
      border-radius: 15px;
      padding: 10px;
      height: 100%;
      & > p {
        font-weight: 700;
        font-size: 25px;
        margin-left: 10px;
      }
      .list {
        padding: 0 5px;
        & > div {
          height: 100px;
          background-color: rgb(249, 249, 249);
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: all 0.2s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          &:hover {
            transform: scale(1.02);
          }
          & > div {
            width: 100%;
            &:first-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 5px;
              span {
                &:nth-child(1) {
                  font-weight: 700;
                }
                &:nth-child(2) {
                  font-size: 10px;
                  opacity: 0.5;
                }
              }
            }
            &:last-child {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
              span {
                &:nth-child(1) {
                  font-weight: 700;
                }
                &:nth-child(2) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
