.searchContainer {
  // z-index: 103;
  background: #fff;
  width: 45%;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  input {
    width: 95%;
    border: 0;
    outline: none;
    &::placeholder {
      opacity: 0.5;
    }
  }
}


.container {
  display: flex;
  align-items: center;
  &:not(:has(.searchContainer)) {
    justify-content: right;
  }
  justify-content: space-between;
  padding: 10px 0;
  .searchContainer {
    @extend .searchContainer;
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    column-gap: 10px;
    a {
      text-decoration: none;
      color: #000;
      margin: 0;
      height: 50px;
    }
    svg {
      cursor: pointer;
      height: 100%;
    }
    hr {
      margin-left: 10px;
      height: 20px;
      width: 1px;
      border: 1px solid #000;
    }
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar {
        width: 40px;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.486);
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        margin-right: 40px;
        font-family: "termina", sans-serif;
        font-size: 14px;
      }
    }
  }
}

.notificationsSections {
  .title {
    span {
      font-size: 22px;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .listgroup {
    display: flex;
    flex-direction: column;

    .listItem {
      display: flex;
      background-color: #f8f8f8;
      border-radius: 18px;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      h4 {
        font-size: 16px;
        font-weight: 800;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: #d1d1d1;
      }
    }
  }
}

.collapseStyle {
  position: absolute;
  top: 10%;
  right: 7%;
  width: 72%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 103;
  overflow: hidden;
}

.listgroup {
  display: flex;
  flex-direction: column;

  & > div {
    border-bottom: 1px solid #d7d5d5;
    &:last-child {
      border-bottom: none !important;
    }
  }
}

.tableRow {
  background-color: #fff;
  padding: 5px;
  //padding-bottom: 10px;
  //border-color: #dee2e6;
  border-bottom: 1px solid #dee2e6;
  &:hover {
    background-color: #f8f8f8;
  }
  .col {
    padding: 0px;
  }
}
.tablePersonInfo {
  width: 150px;
  h4 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  h6 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  p {
    font-size: 10px;
    font-weight: 400;
    color: #a8a8a8;
    margin: 0;
  }
}

.tableData {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: none;
  padding: 0.4rem 0.5rem;
  border-right: 1px solid #ddd;
  margin-top: 5px;
  border-bottom: none;
  text-align: center;

  p {
    font-size: 10px;
    font-weight: 400;
    color: #a8a8a8;
    margin: 0;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0;
    margin-top: 5px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 102;
  transition: all 0.5s ease-in-out;
}
