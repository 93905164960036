.rbc-off-range-bg {
  background: #fff;
}

.rbc-header {
  text-align: end;
}

.rbc-month-view {
  border: 0;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-header + .rbc-header {
  border-left: 0;
}

.rbc-row-bg {
  row-gap: 10px;
}

/* Adjust event styling for better visibility */
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: rgba(0, 0, 0, 0.1) !important; /* Slightly darker for visibility */
  color: #000;
  padding: 4px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

/* Ensure selected events maintain the same styling */
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background: rgba(0, 0, 0, 0.15) !important;
}

/* Improve time slot visibility in Week and Day views */
.rbc-time-slot {
  min-height: 40px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Ensure events are fully visible and not cut off */
.rbc-event-label {
  font-size: 12px;
  padding: 2px;
  color: #000;
}

/* Adjust day view event height */
.rbc-day-slot .rbc-event {
  min-height: 25px !important;
  padding: 5px;
}

/* Improve time grid layout for Week and Day views */
.rbc-time-content {
  border-top: 0;
}

.rbc-time-content > * + * > * {
  border-left: 0;
}

/* Adjust event title display */
.rbc-event-content {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Improve calendar grid alignment */
.rbc-timeslot-group {
  min-height: 50px !important;
}
