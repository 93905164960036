.modalContainer {
  background-color: rgba(0, 0, 0, 0.527);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .clickAway {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .modal {
    background-color: #f0f0f0;
    width: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    row-gap: 10px;

    & > button {
      background: transparent;
      border: 0;
      outline: none;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #747474;
    }

    .content {
      width: 100%;
      background-color: #fff;
      border-radius: 15px;
      padding: 15px 25px;

      h3 {
        font-family: "termina", sans-serif;
        font-size: 20px;
        margin-bottom: 0;
      }

      & > span:nth-child(2) {
        opacity: 0.5;
      }

      .data {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 20px; // Added gap for better spacing
        flex-wrap: wrap;

        & > div {
          width: 100%;

          &:first-child {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            span {
              margin: 5px 0;
            }

            input {
              background-color: #f8f8f8;
              border: 0;
              outline: none;
              border-radius: 5px;
              padding: 5px 10px;
              width: max(40%, 300px);

              &:not(.nameInput) {
                width: 90%;
              }
            }
          }
        }
      }

      .desc {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        span {
          margin: 5px 0;
        }

        textarea {
          width: 100%;
          height: 200px;
          background-color: #f8f8f8;
          border: 0;
          outline: none;
          border-radius: 5px;
          padding: 5px 10px;
          resize: none;
        }
      }

      .dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed #7e7e7e;
        padding: 16px;
        text-align: center;
        width: 100%;
        background-color: #f8f8f8;
        border-radius: 10px;
        position: relative;
      }
      
      .dropzone input {
        display: inline-block;
        margin-bottom: 10px;
        cursor: pointer;
        width: auto; /* This makes sure the input is centered */
        padding-left: 125px;
      }
      
      .dropzone span {
        opacity: 0.5;
        margin-top: 10px;
        display: block; /* Ensure the text is a block element */
      }
      
      .dropzone input[type="file"] {
        text-align: center; /* Ensures the input text is aligned correctly */
        display: block;
        margin: 0 auto; /* Center the input itself */
      }
      
      
      
      
      
    }      

    .btns {
      button {
        border: 0;
        outline: none;
        margin-left: 10px;
        padding: 7px 10px;
        border-radius: 10px;
        width: 150px;
        font-weight: 700;

        &:active {
          transform: translateY(2px);
        }

        &:nth-child(1) {
          background-color: #fff;
          border: 1px solid #dddddd;
          color: #898989;
        }

        &:nth-child(2) {
          background-color: #1bbb4e;
          color: #fff;
        }
      }
    }
  }
}


