.container {
  .content {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 25px;

    .title {
      h4 {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin: 0;
      }
      p {
        color: rgb(186, 186, 186);
      }
    }

    .table {
      font-weight: 500;
      border: 1px solid rgb(249, 249, 249);
      border-radius: 7px;
      box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.089);
      padding-bottom: 100px;

      .tableHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        div {
          span {
            &:nth-child(2) {
              background-color: rgb(240, 240, 240);
              margin-left: 5px;
              padding: 0 5px;
              border-radius: 3px;
            }
          }
        }

        button.importButton {
          background-color: rgb(14, 95, 254);
          border: 0;
          color: #fff;
          padding: 5px 30px;
          border-radius: 7px;
          margin-right: 20px;

          &:active {
            transform: translateY(2px);
          }
        }
      }

      .row {
        border-bottom: 1px solid rgb(217, 217, 217);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;

        &:nth-child(2) {
          & > div {
            color: rgb(0, 0, 0);
            font-weight: 600;
          }
        }

        & > div:first-child {
          width: 60%; /* Adjust width for the first column */
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & > div:not(:first-child) {
          flex: 1; /* Let the remaining columns share the remaining space equally */
        }

        svg {
          cursor: pointer;
          font-size: 20px;
          margin-left: 10px;
          transition: all 0.1s;

          &:hover {
            transform: scale(1.1);
          }

          &:nth-child(1) {
            color: #5cb559;
          }

          &:nth-child(2) {
            color: #d34c43;
          }
        }
      }
    }
  }
}
