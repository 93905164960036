.suffix {
  background-color: rgb(51, 82, 106);
  color: #fff;
  border-radius: 10px;
  padding: 0 5px;
}
.btn {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: #fff;
  cursor: pointer;
  float: right;
  padding: 5px;
  background-color: black;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
}
