.container {
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > p {
    font-weight: 700;
    font-size: 25px;
    margin-left: 10px;
  }

  .msgs {
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    margin-bottom: 10px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px;

      &.currentUser {
        justify-content: flex-end;
        & > div {
          flex-direction: row-reverse;
        }
      }

      & > div {
        background-color: rgb(249, 249, 249);
        max-width: 80%; /* Adjust the max-width as needed */
        border-radius: 10px;
        padding: 10px; /* Adjust the padding as needed */
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
          p {
            &:nth-child(1) {
              font-weight: 700;
              margin-bottom: 0;
            }
            &:last-child {
              margin-bottom: 0;
              opacity: 0.5;
              font-size: 11px;
            }
          }
        }

        .avatar {
          width: 40px;
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.486);
          border-radius: 50%;
          margin: 0 10px;
          flex-shrink: 0; /* Ensure the avatar doesn't shrink to fit its container */
          //align-self: flex-start; 
        }
      }
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 10px;

    input {
      flex-grow: 1; /* Allow the input to grow to fill the available space */
      background-color: rgb(249, 249, 249);
      border: 0;
      outline: none;
      border-radius: 15px;
      padding: 10px;
    }

    button {
      border: 0;
      border-radius: 15px;
      background-color: #000;
      color: #fff;
      height: 100%;
      width: 70px;
    }
  }
}