
.modal {
  background-color: #f0f0f0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  row-gap: 10px;
  & > button {
    background: transparent;
    border: 0;
    outline: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #747474;
  }
  .content {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px;
    h3 {
      font-family: "termina", sans-serif;
      font-size: 20px;
      margin-bottom: 0;
    }
    & > span:nth-child(2) {
      opacity: 0.5;
    }
    .inputs {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      span {
        margin: 5px 0;
      }
      input {
        background-color: #f8f8f8;
        border: 0;
        outline: none;
        border-radius: 5px;
        padding: 5px 10px;
        width: 100%;
      }
    }
  }
  .btns {
    margin-top: 10px;
    button {
      border: 0;
      outline: none;
      margin-left: 10px;
      padding: 7px 10px;
      border-radius: 10px;
      width: 150px;
      font-weight: 700;
      &:active {
        transform: translateY(2px);
      }
      &:nth-child(1) {
        background-color: #fff;
        border: 1px solid #dddddd;
        color: #898989;
      }
      &:nth-child(2) {
        background-color: #fff;
        border: 1px solid #dddddd;
        color: #898989;
      }
      &:nth-child(3) {
        background-color: #1bbb4e;
        color: #fff;
      }
    }
    button[type="submit"] {
      background-color: #1bbb4e;
      color: #fff;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.dropdownStyle {
  width: 100%;
  border: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 11.5px 5px;
  outline: none;
  resize: vertical;
}