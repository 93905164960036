.container {
  width: 900px;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 10px;
  @media screen and (max-width: 1330px) {
    width: 100%;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(223, 229, 234);

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    subtitle {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }

    button {
      color: rgb(55, 153, 235);
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
    }
  }
  .subHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .field {
    margin: 10px 0;
    // padding: 5px 20px;
    p {
      font-weight: 500;
      margin-bottom: 10px;
    }
    input[type="text"],
    textarea {
      width: 100%;
      border: #f8f8f8;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 10px 5px;
      outline: none;
      resize: vertical;
    }
  }
}

.cardList {
  padding: 20px;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }
  .imageFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 30px;
    color: #bfbfbf;

    span {
      margin-left: 5px;
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
    button {
      padding: 8px 62px;
      border-radius: 10px;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    width: 120px;
  }
}

.secondary {
  background-color: #fff;
  color: grey;
  margin-right: 10px;
  border: grey;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.dropdown {
  button {
    padding: 8px 20px !important;

    &::after {
      display: none;
    }
  }
}
