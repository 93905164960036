.container {
  .calendarContainer {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 25px;
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    .calendar {
      height: 700px;
    }
  }
}
.eventBadge {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 50%;
  &.error {
    background-color: red;
  }
  &.success {
    background-color: green;
  }
  &.warning {
    background-color: yellow;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: relative;
}

.addButton {
  background-color: #000000; /* Adjust color if needed */
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.2s ease-in-out;
}

.addButton:hover {
  background-color: #1e1e1e;
}


/* Modal.module.scss */
.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  label {
    font-weight: bold;
  }
  
  input, textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
}

.btns {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  
  .deleteBtn {
    background: #dc3545;
    color: white;
  }
  
  .saveBtn {
    background: #28a745;
    color: white;
  }
}

.weeklyEventsContainer {
  margin-top: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.eventTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.eventTable th,
.eventTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.eventTable th {
  background: #f4f4f4;
  font-weight: bold;
}
