.modalContainer {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .clickAway {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .modal {
    background-color: #f0f0f0;
    width: 70%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;

    & > button {
      background: transparent;
      border: none;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #747474;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      &:hover {
        color: #000;
      }
    }

    .content {
      padding: 25px;

      h3 {
        font-family: "termina", sans-serif;
        font-size: 20px;
        margin-bottom: 10px;
      }

      span {
        opacity: 0.5;
        font-size: 14px;
        display: block;
        margin-bottom: 20px;
      }

      .inputs {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
          color: #333;
        }

        input,
        textarea,
        select {
          background-color: #f8f8f8;
          border: 1px solid #ddd;
          border-radius: 5px;
          padding: 10px;
          width: 100%;
          font-size: 14px;
          margin-bottom: 15px;
          outline: none;

          &:focus {
            border-color: #1bbb4e;
          }
        }

        textarea {
          resize: none;
        }

        .error {
          color: red;
          font-size: 12px;
          margin-top: -10px;
          margin-bottom: 10px;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      padding: 15px 25px;
      border-top: 1px solid #ddd;

      button {
        border: none;
        margin-left: 10px;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s;

        &:active {
          transform: translateY(2px);
        }

        &:nth-child(1) {
          background-color: #fff;
          border: 1px solid #ddd;
          color: #898989;

          &:hover {
            border-color: #ccc;
            color: #555;
          }
        }

        &:nth-child(2) {
          background-color: #1bbb4e;
          color: #fff;

          &:hover {
            background-color: #159a3d;
          }
        }
      }
    }
  }

  .modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin: 10px 0;
      text-align: left;
      font-size: 14px;
    }

    button {
      margin-top: 20px;
      padding: 8px 16px;
      background-color: #1bbb4e;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #159a3d;
      }
    }
  }
}

.readMore {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;

  &:hover {
    text-decoration: none;
  }
}

.actionIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  .icon {
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .icon:nth-child(1) {
    color: blue; /* Eye Icon */
  }

  .icon:nth-child(2) {
    color: green; /* Edit Icon */
  }

  .icon:nth-child(3) {
    color: red; /* Delete Icon */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
