.container {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    .searchContainer {
      background: #fff;
      width: 50%;
      border-radius: 15px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      input {
        width: 95%;
        border: 0;
        outline: none;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }

    & > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      column-gap: 10px;

      svg {
        cursor: pointer;
      }

      hr {
        margin-left: 10px;
        height: 20px;
        width: 1px;
        border: 1px solid #000;
      }

      .profile {
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: 40px;
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.486);
          border-radius: 50%;
          margin-right: 10px;
        }

        span {
          margin-right: 80px;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    padding: 10px 0;
    column-gap: 10px;

    & > div {
      width: 100%;
      height: 100%;

      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        div {
          background-color: #fff;
          border-radius: 15px;
          padding: 10px 20px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;

          p {
            font-weight: 700;
            font-size: 20px;
          }

          span {
            font-weight: 900;
            font-size: 25px;
          }
        }
      }

      &:last-child {
        background-color: #fff;
        border-radius: 15px;
        padding: 10px;

        & > p {
          font-weight: 700;
          font-size: 25px;
          margin-left: 10px;
        }

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .msgs {
          overflow: auto;
          width: 100%;
          margin-bottom: 10px;

          & > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;

            &.currentUser {
              justify-content: flex-end;

              & > div {
                flex-direction: row-reverse;
              }
            }

            & > div {
              background-color: rgb(249, 249, 249);
              width: 60%;
              border-radius: 10px;
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              div {
                p {
                  &:nth-child(1) {
                    font-weight: 700;
                    margin-bottom: 0;
                  }

                  &:last-child {
                    margin-bottom: 0;
                    opacity: 0.5;
                    font-size: 11px;
                  }
                }
              }

              .avatar {
                width: 40px;
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.486);
                border-radius: 50%;
                margin: 0 10px;
              }
            }
          }
        }

        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          column-gap: 10px;

          input {
            width: 100%;
            background-color: rgb(249, 249, 249);
            border: 0;
            outline: none;
            border-radius: 15px;
            padding: 10px;
          }

          button {
            border: 0;
            border-radius: 15px;
            background-color: #000;
            color: #fff;
            height: 100%;
            width: 70px;
          }
        }
      }
    }
  }

  .feed {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;

    & > p {
      font-weight: 700;
      font-size: 25px;
    }

    table {
      width: 100%;
      background-color: rgb(251, 251, 251);

      thead {
        background-color: #000;
        color: #fff;

        tr {
          th {
            padding: 8px 0 8px 5%;

            &:first-child {
              border-radius: 5px 0 0 5px;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding-left: 5%;
            padding: 5px 0 5px 5%;
            font-size: 20px;
          }
        }
      }
    }
  }

  .filterCard {
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    margin-right: 10px;
    width: 100%;
    height: 3rem;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: 10px;
    background: #fff;
    // border: 1px solid #ddd;
    cursor: pointer;
    margin-left: 10px;
    color: #212529 !important;

    & > div {
      // width: 100%;
      // height: 100%;
      // font-size: 14px;
      // font-weight: 500;
      // color: #a8a8a8;
      // padding: 10px;

      // &:first-child {
      //   border-right: 1px solid #ddd;
      // }
    }
  }
  .card {
    height: 3rem;
    margin: 0px 5px;
    width: 20%;
    // max-width: 155px;
  }

  .title {
    margin-top: 20px;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    hr {
      color: rgba(0, 0, 0, 0.486);
    }
  }

  .tablePersonInfo {
    h4 {
      font-size: 15px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }

    h6 {
      font-size: 12px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }

    p {
      font-size: 10px;
      font-weight: 400;
      color: #a8a8a8;
      margin: 0;
    }
  }

  .tableRow {
    background-color: #fff;

    &:hover {
      background-color: #f8f8f8;
    }

    td {
      padding: 0px;
    }
  }

  .tableData {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: none;
    padding: 0.5rem 0.5rem;
    border-right: 1px solid #ddd;
    margin-top: 5px;
    border-bottom: none;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #a8a8a8;
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin: 0;
      margin-top: 5px;
    }
  }

  .tableDataDark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: none;
    background-color: #000;
    padding: 0.5rem;
    margin-top: 5px;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #a8a8a8;
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      margin: 0;
      margin-top: 5px;
    }
  }

  .popup {
    width: 300%;
    height: 300px;
    overflow-y: scroll;
    padding-top: 20px;
    position: absolute;
    background: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    top: 50px;
    left: 0;
    border-radius: 6px;
    border: 1px solid #ddd;
    z-index: 2000;

    header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 0;
    }

    footer {
      display: flex;
      justify-content: end;
    }

    label {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      input {
        cursor: pointer;
      }
    }

    div {
      padding: 0 24px;
    }

    h3 {
      font-size: 20px;
      padding: 0 24px 24px 40%;
    }

    h4 {
      font-size: 16px;
      padding: 0 24px 24px;
    }

    p {
      font-size: 14px;
      margin: 0 0 0 10px;
    }
  }
}