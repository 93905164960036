.container {
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 10px;

  @media screen and (max-width: 1330px) {
    width: 100%;
  }

  .title {
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(223, 229, 234);

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    subtitle {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }
    // button {
    //   background-color: rgb(55, 153, 235);
    // }

    button {
      color: rgb(55, 153, 235);
      background-color: transparent;
      border: 1 solid rgb(55, 153, 235);
      outline: none;
      cursor: pointer;
      &:hover {
        background-color: rgb(55, 153, 235);
        color: #fff;
      }
    }
  }

  .contentBody {
    height: 60vh;
    overflow-y: auto;

    padding: 20px 0px;
    padding-bottom: 10px;

    h6 {
      font-size: 18px;
      font-weight: 400px;
      margin-bottom: 20px;
    }

    table {
      thead {
        tr {
          th {
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
      tbody {
        tr {
          & > td {
            font-size: 12px;
            font-weight: 400;
            &:first-child {
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }

  .listItems {
    font-size: 12px;
  }
  .subHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .field {
    margin: 10px 0;
    // padding: 5px 20px;
    p {
      font-weight: 500;
      margin-bottom: 10px;
    }
    input[type="text"],
    textarea {
      width: 100%;
      border: #f8f8f8;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 10px 5px;
      outline: none;
      resize: vertical;
    }
  }
}

.cardList {
  padding: 20px;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }
  .imageFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 30px;
    color: #bfbfbf;

    span {
      margin-left: 5px;
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
    button {
      padding: 8px 62px;
      border-radius: 10px;
    }
  }
}

.cardList {
  padding: 20px;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .imageFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 30px;
    color: #bfbfbf;

    span {
      margin-left: 5px;
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
    button {
      padding: 8px 62px;
      border-radius: 10px;
    }
  }
}

.permissions {
  border: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  outline: none;
  resize: vertical;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;

  select {
    background-color: #f8f8f8;
    border: #7a7a7a 1px dashed;
    border-radius: 8px;
    width: 200px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  button {
    padding: 8px 15px;
    border: #7a7a7a 1px dashed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: initial;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.addFormModalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex !important;
  justify-content: center;
  align-items: center;

  .modalDialog {
    width: 500px !important;
    padding: 12px 24px;

    .modalHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;
      padding: 8px;

      label {
        font-size: 12px;
        font-weight: 600;
        padding-bottom: 4px;
      }

      input {
        height: 30px;
        border: none;
        border-radius: 4px;
        box-shadow: 0 0 2px #ccc;
        padding: 8px;
        cursor: pointer;

        &:hover,
        &:focus {
          border: 1px solid #ccc;
          outline: none; // Optional, remove the default outline
        }
      }

      .error {
        padding-top: 4px;
        font-size: 10px;
        color: red;
      }
    }

    .modalFooter {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      width: 100%;
    }
  }
}

.passwordContainer {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row !important;
  width: 100%;
}

.passwordField {
  flex: 1;
  width: 100%;

  padding-right: 50px; /* Add some padding to make space for the button */
}

.toggleButton {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  color: #007bff; /* or any color you prefer */
  padding: 0;
  margin: 0;
}
