.p-dropdown, .p-multiselect,.p-focus, .p-dropdown:hover{
    border: none !important;
}
.p-dropdown >input , .p-multiselect, .p-dropdown, .btn{
    box-shadow: none !important;
    outline: none !important;
}

.slider-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  /* Define the styles for each slider column */
  .slider-column {
    flex: 0 0 calc(33.33% - 10px); /* Each column takes one-third of the width with some spacing */
    margin-right: 30px; /* Adjust spacing between columns */
  }
  
  /* Adjust the width of the sliders to fit their container */
  .p-slider-horizontal {
    width: 100%;
  }