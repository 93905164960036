.container {
  .content {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 25px;

    .headerRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h4 {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin: 0;
      }

      .addButton {
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          background-color: rgb(49, 49, 49);
        }

        &:active {
          transform: translateY(2px);
        }
      }
    }

    .subTitle {
      color: rgb(186, 186, 186);
      margin-bottom: 20px;
    }

    .table {
      width: 100%;
      border-collapse: collapse;
      background-color: #fff;
      border: 1px solid rgb(249, 249, 249);
      border-radius: 7px;
      box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.089);
    
      th,
      td {
        padding: 15px;
        text-align: left;
        //border-bottom: 1px solid rgb(217, 217, 217,0.2);
        vertical-align: middle; /* Ensure vertical alignment */
        height: 60px; /* Set consistent height */
        font-weight: 500;
        line-height: 1.5;
      }
    
      th {
        background-color: #000000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: rgb(255, 255, 255);
      }
    
      tr {
        transition: background-color 0.2s ease;
        border-bottom: 1px solid rgb(217, 217, 217);
    
        &:hover {
          background-color: #f9f9f9;
        }
    
        &.completedRow {
          background-color: #f4f4f4;
          color: #999;
        }
      }
    
      td.actionIcons {
        display: flex; /* Enable flexbox */
        justify-content: flex-start; /* Align icons to the left */
        align-items: center; /* Vertically center icons */
        gap: 15px; /* Add space between icons */
    
        .icon {
          font-size: 20px;
          cursor: pointer;
          transition: transform 0.2s ease, color 0.2s ease;
        
          &:hover {
            transform: scale(1.1); /* Slight zoom on hover */
          }
        
          &.view {
            color: blue; /* Default color for view */
          }
        
          &.edit {
            color: green; /* Default color for edit */
          }
        
          &.delete {
            color: red; /* Default color for delete */
          }
        
          &.completed {
            color: grey !important; /* Override colors for completed rows */
            //cursor: not-allowed; /* Optional: Make it clear that the icons are inactive */
            &:hover {
              transform: none; /* Prevent hover effect */
            }
          }
        }
        
      }
    }
    
    
    

    .importButton {
      background-color: rgb(14, 95, 254);
      border: 0;
      color: #fff;
      padding: 5px 30px;
      border-radius: 7px;
      margin-right: 20px;

      &:active {
        transform: translateY(2px);
      }
    }

    .row {
      //border-bottom: 1px solid rgb(217, 217, 217);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;

      svg {
        cursor: pointer;
        font-size: 20px;
        margin-left: 10px;

        transition: all 0.1s;
        &:hover {
          transform: scale(1.1);
        }

        &:nth-child(1) {
          color: #5cb559;
        }

        &:nth-child(2) {
          color: #d34c43;
        }
      }

      & > div {
        width: 400px;
      }
    }
  }
}

.readMore {
  color: rgb(32, 32, 32);
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
}

.modalContainer {
  position: fixed; /* Fix to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dimmed background */
  z-index: 1000; /* Ensure it overlays all content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 10px 0;
    line-height: 1.5;
    text-align: left;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #313131;
    }
  }
}


.requestButton {
  background-color: #007bff;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.viewButton {
  background-color: #28a745;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #218838;
  }
}

.icon {
  //cursor: pointer;
  transition: color 0.3s ease;
  font-size: 20px;

  &.delete {
    color: red;

    &:hover {
      color: rgb(222, 0, 0); 
    }
  }
}
